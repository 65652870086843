<template>
  <FateCreativeVideo dialog-name="FATE - Creative Videos" />
</template>
<script>
import FateCreativeVideo from '@/views/app/fate/CreativeVideo'

export default {
  name: 'FateCreativeVideoWrapper',
  components: {
    FateCreativeVideo
  }
}
</script>
