<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title class="d-flex" v-if="!showInformation" >
        <div class="text-uppercase pl-4 mr-auto">
          {{ title }}
        </div>
        <div class="d-flex justify-space-between pr-0 pb-2">
          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="filter"
            @on-click="showFilter = true"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />

          <o-drawer
            v-if="!busy"
            :auto-close="true"
            :actions="[
              {
                key: 'on-item-reset',
                label: 'RESET',
                icon: 'mdi-refresh',
                color: 'warning',
              },
              {
                key: 'on-item-search',
                label: 'SEARCH',
                icon: 'mdi-text-box-search-outline',
                color: 'success',
              },
            ]"
            button-icon="mdi-filter-plus-outline"
            :title="`FILTERS`"
            :use-button="false"
            :visible="showFilter"
            width="50vw"
            @on-item-search="reset()"
            @on-item-reset="reset(true)"
            @on-close="showFilter = false"
          >
            <v-row class="pa-0 ma-0">
              <v-col cols="12" class="pa-0 ma-0">
                <o-combo-box
                  v-model="parameters.status"
                  :busy="dropBusy"
                  label="Status"
                    :items="['Approved',
                      'Encoding',
                      'Encoding Error',
                      'Invalid',
                      'Preparing',
                      'Processed',
                      'Ready',
                      'Rejected',
                      'Syncing']"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="12" class="pa-0 ma-0">
                <o-input
                  v-model="parameters.uploaded_by"
                  :busy="dropBusy"
                  label="Uploaded By"
                />
              </v-col>
              <v-col cols="12" class="pa-0 ma-0">
                <o-input
                  v-model="parameters.video_title"
                  :busy="dropBusy"
                  label="Title"
                />
              </v-col>
              <v-col cols="12" class="pa-0 ma-0">
                <o-input
                  v-model="parameters.filename"
                  :busy="dropBusy"
                  label="Filename"
                />
              </v-col>
            </v-row>
          </o-drawer>
        </div>
      </v-card-title>
      <v-divider v-if="!showInformation"  />
      <o-drawer
        :actions="[
          {
            key: 'on-item-save',
            label: 'Save',
            icon: 'mdi-content-save',
            color: 'success',
          },
        ]"
        :title="`${action} API Key`"
        :visible="action !== ''"
        width="50vw"
        @on-close="action = ''"
        @on-item-save="save"
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <o-combo-box
            v-model="model.user_id"
            :busy="busy"
            label="User"
            :items="users"
          />

          <o-input
            v-model="model.name"
            :busy="busy"
            label="Name"
            :is-required="true"
            :rules="[(v) => !!v || 'This is required']"
          />

          <o-input
            v-model="model.email"
            :busy="busy"
            label="Email"
            :is-required="true"
            :rules="[(v) => !!v || 'This is required']"
          />

          <o-input
            v-if="action === 'edit'"
            v-model="model.token"
            :busy="busy"
            label="Token"
            :is-required="true"
            :rules="[(v) => !!v || 'This is required']"
          />

          <v-row class="p0-0 ma-0 justify-center align-center">
            <v-col cols="10" class="pa-0 ma-0">
              <span class="text-uppercase">ORIGIN</span>
              <small
                v-if="model.origin && model.origin.length === 0"
                class="danger"
                >(None was specified)</small
              >
            </v-col>
            <v-col cols="2" class="pa-0 ma-0" align="end">
              <o-button
                :busy="true"
                color="success"
                icon="mdi-plus"
                type="icon"
                @on-click="model.origin.push('')"
              />
            </v-col>
          </v-row>

          <v-divider />
          <template v-if="model.origin">
            <v-row
              v-for="(x, i) in model.origin"
              :key="i"
              class="pl-10 ma-0 justify-center align-center"
            >
              <v-col cols="10" class="pa-0 ma-0">
                <o-input
                  v-model="model.origin[i]"
                  :busy="busy"
                  :label="`${i + 1}`"
                  :is-required="true"
                  :rules="[(v) => !!v || 'This is required']"
                />
              </v-col>
              <v-col cols="2" class="pa-0 ma-0">
                <o-button
                  :busy="true"
                  color="danger"
                  icon="mdi-trash-can-outline"
                  type="icon"
                  @on-click="model.origin.splice(i, 1)"
                />
              </v-col>
            </v-row>
            <v-divider />
          </template>
          <o-check-box
            v-model="model.active"
            class="pt-4"
            :busy="busy"
            label="Active"
          />

          <o-input
            v-model="model.notes"
            :busy="busy"
            label="Notes"
            type="textarea"
          />
        </v-form>
      </o-drawer>
      <v-card-text class="text-primary" v-if="!showInformation" >
        <o-table
          ref="table"
          :busy="busy"
          type="rest"
          :height="getHeight(450)"
          :fixed-header="true"
          method="GET"
          :has-paging="true"
          :rest="{
            url: `/rest/creative_videos?${getParameters}&order=${this.parameters.order}&dir=${this.parameters.dir}`,
            payload: null,
          }"
          :headers="[
            {
              text: '#',
              align: 'end',
              sortable: false,
              value: 'row_no',
              type: 'int',
              width: 100,
            },
            {
              text: 'Thumbnail',
              align: 'start',
              sortable: false,
              value: 'Thumbnail',
              type: 'slot',
              width: 100,
            },
            {
              text: 'Status',
              align: 'start',
              sortable: false,
              value: 'Status',
              type: 'slot',
            },
            {
              text: 'Encoded Status',
              align: 'start',
              sortable: false,
              value: 'EncodedStatus',
              type: 'slot',
            },
            {
              text: 'ID',
              align: 'start',
              sortable: false,
              value: '_id',
              type: 'text',
            },
            {
              text: 'Title',
              align: 'start',
              sortable: false,
              value: 'VideoTitle',
              type: 'text',
            },
            {
              text: 'Filename',
              align: 'start',
              sortable: false,
              value: 'FileName',
              type: 'text',
            },
            {
              text: 'Quality',
              align: 'start',
              sortable: false,
              value: 'VideoQuality',
              type: 'text',
            },
            {
              text: 'Loudness Check',
              align: 'center',
              sortable: false,
              value: 'LoudnessCheck',
              type: 'boolean',
              width: 150,
            },
            {
              text: 'Created Date',
              align: 'start',
              sortable: false,
              value: 'CreatedDate',
              type: 'date-time',
            },
            {
              value: 'action',
              type: 'action',
              actions: [
                {
                  key: 'on-item-view',
                  label: 'View',
                  icon: 'mdi-video',
                  color: 'success',
                },
              ],
            },
          ]"
          @set-busy="setBusy"
          @on-item-view="onItemView"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === 'Thumbnail'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <img
                :src="
                  `https://octillion-creative-videos.s3.us-east-2.amazonaws.com/${row.prop.item.Thumbnail}`
                "
              />
            </div>
             <div
              v-if="row.prop.key === 'Status' || row.prop.key === 'EncodedStatus'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <span v-if="['Encoding Error', 'Rejected', 'Invalid'].includes(row.prop.item[row.prop.key])" class="text-danger">
              {{row.prop.item[row.prop.key]}}</span>
              <span v-else class="text-success">
              {{row.prop.item[row.prop.key]}}</span>
            </div>
          </template>
        </o-table>
      </v-card-text>

      <v-card v-if="showInformation" elevation="2" shaped>
        <v-card-title>
          <v-row class="ma-0 pa-0">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="6"
              xl="6"
              class="ma-0 pa-0"
            >
              <div class="text-uppercase pl-4 mr-auto">
                FATE - Creative Video Information
              </div>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="6"
              xl="6"
              class="text-end ma-0 pa-0"
            >

              <o-button
                :busy="busy"
                color="success"
                icon="mdi-sync"
                type="label-icon"
                label="Retry"
                @on-click="retry()"
              />

              <o-button
                :busy="busy"
                color="primary"
                icon="mdi-keyboard-backspace"
                type="label-icon"
                label="Back"
                @on-click="
                  showInformation = false;
                  showFilter = false;
                "
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />

        <div class="ml-10 pb-5 mr-5">
          <v-row class="o-container-content"> 
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
              <div class="d-flex justify-space-between">
                <p>Thumbnail:</p>
                <img
                  v-if="model.Thumbnail !== ''"
                  height="100"
                  :src="`https://octillion-creative-videos.s3.us-east-2.amazonaws.com/${model.Thumbnail}`"
                />
              </div>
              <div class="d-flex justify-space-between mt-6">
                <p>ID:</p>
                <span>{{ model._id }}</span>
              </div>
               <div class="d-flex justify-space-between">
                <p>Title:</p>
                <span>{{ model.VideoTitle }}</span>
              </div>
              <div class="d-flex justify-space-between">
                <p>Status:</p>
                <span v-if="['Encoding Error', 'Rejected', 'Invalid'].includes(model.Status)" class="text-danger">{{ model.Status }}</span>
                <span v-else class="text-success">{{ model.Status }}</span>
              </div>
              <div class="d-flex justify-space-between">
                <p>Filename:</p>
                <span>{{ model.FileName }}</span>
              </div>
              <div class="d-flex justify-space-between">
                <p>Quality:</p>
                <span>{{ model.VideoQuality }}</span>
              </div>
              <div class="d-flex justify-space-between">
                <p>Uploaded By:</p>
                <span>{{ model.UploadedBy }}</span>
              </div>
              <div class="d-flex justify-space-between">
                <p>Loudness Check:</p>
                <v-chip
                  class=""
                  :color="model.LoudnessCheck ? 'success' : 'danger'"
                  label
                  small
                  text-color="white"
                >
                  <v-icon small left>
                    {{ model.LoudnessCheck ? "mdi-check" : "mdi-close" }}
                  </v-icon>
                  {{ model.LoudnessCheck ? "Yes" : "No" }}
                </v-chip>
              </div> 
              <div class="d-flex justify-space-between">
                <p>Date Created:</p>
                <span>{{ getDateTime(model.CreatedDate) }}</span>
              </div>
              <div class="d-flex justify-space-between" v-if="model.EncodingError">
                <p class="text-danger">Encoding Error:</p>
              </div>
              <div class="d-flex justify-space-between" v-if="model.EncodingError">
                <p class="pl-10">{{ model.EncodingError }}</p>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
              <o-table
                :busy="true"
                type="array"
                :has-paging="false"
                :no-count="true"
                :headers="[
                  {
                    text: 'Meta Data',
                    align: 'start',
                    sortable: false,
                    value: 'label',
                    type: 'slot',
                  },
                  {
                    text: 'Allowed',
                    align: 'start',
                    sortable: false,
                    value: 'allowed',
                    type: 'text',
                  },
                  {
                    text: 'Current',
                    align: 'end',
                    value: 'current',
                    type: 'text',
                  },
                  {
                    text: 'Status',
                    align: 'start',
                    sortable: false,
                    value: 'status',
                    type: 'slot',
                  },
                ]"
                :data="getMetaData(model.Message)"
              >
                <template v-slot:slot="row">
                  <div
                    v-if="row.prop.key === 'label'"
                    class="text-left text-capitalize"
                  >
                    {{ row.prop.item.label.toString().replace(/_/g, " ") }}
                  </div>
                  <div v-if="row.prop.key === 'status'" class="text-center">
                    <v-chip
                      class="text-capitalize"
                      :color="
                        row.prop.item.status == 'Approve' ? 'success' : 'danger'
                      "
                      label
                      small
                      text-color="white"
                    >
                      <v-icon small left>
                        {{
                          row.prop.item.status == "Approve"
                            ? "mdi-check"
                            : "mdi-close"
                        }}
                      </v-icon>
                      {{ row.prop.item.status }}
                    </v-chip>
                  </div>
                </template>
              </o-table>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
              <h5>Transcoded</h5>
              <div class="d-flex justify-space-between mt-6">
                <p>Status:</p>
                <span v-if="['Encoding Error', 'Rejected', 'Invalid'].includes(model.EncodedStatus)" class="text-danger">{{ model.Status }}</span>
                <span v-else class="text-success">{{ model.EncodedStatus }}</span>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
              <h5>&nbsp;</h5>
              <o-table
                :busy="true"
                type="array"
                :has-paging="false"
                :no-count="true"
                :headers="[
                  {
                    text: 'Meta Data',
                    align: 'start',
                    sortable: false,
                    value: 'label',
                    type: 'slot',
                  },
                  {
                    text: 'Allowed',
                    align: 'start',
                    sortable: false,
                    value: 'allowed',
                    type: 'text',
                  },
                  {
                    text: 'Current',
                    align: 'end',
                    value: 'current',
                    type: 'text',
                  },
                  {
                    text: 'Status',
                    align: 'start',
                    sortable: false,
                    value: 'status',
                    type: 'slot',
                  },
                ]"
                :data="getMetaData(model.EncodedMessage)"
              >
                <template v-slot:slot="row">
                  <div
                    v-if="row.prop.key === 'label'"
                    class="text-left text-capitalize"
                  >
                    {{ row.prop.item.label.toString().replace(/_/g, " ") }}
                  </div>
                  <div v-if="row.prop.key === 'status'" class="text-center">
                    <v-chip
                      class="text-capitalize"
                      :color="
                        row.prop.item.status == 'Approve' ? 'success' : 'danger'
                      "
                      label
                      small
                      text-color="white"
                    >
                      <v-icon small left>
                        {{
                          row.prop.item.status == "Approve"
                            ? "mdi-check"
                            : "mdi-close"
                        }}
                      </v-icon>
                      {{ row.prop.item.status }}
                    </v-chip>
                  </div>
                </template>
              </o-table>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: "FateCreativeVideo",
  mixins: [RestApi],
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "FATE - Creative Videos",
  },
  data() {
    return {
      title: "FATE - Creative Videos",
      showFilter: false,  
      showInformation: false,    
      users: [],
      busy: false,
      dropBusy: false,
      action: "",
      valid: false,
      parameters: {
        status: [],
        uploaded_by: "",
        video_title: "",
        filename: "",
        order: "CreatedDate",
        dir: "desc",
        page: 1,
        page_size: 20,
      },
      baseModel: {
        id: 0,
        user_id: 0,
        name: "",
        email: "",
        origin: [],
        token: "",
        active: false,
        notes: "",
        created_at: "",
        created_by: 0,
        updated_at: "",
        updated_by: 0,
        deleted_at: null,
        deleted_by: 0,
      },
      model: {},
      originalModel: {},
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
    getParameters() {
      const queryString = [];
      if (this.parameters.status.length > 0) {
        queryString.push(`status=${this.parameters.status.join(",")}`);
      }
      if (this.parameters.uploaded_by != "") {
        queryString.push(`uploaded_by=${this.parameters.uploaded_by}`);
      }
      if (this.parameters.video_title != "") {
        queryString.push(`video_title=${this.parameters.video_title}`);
      }
      if (this.parameters.filename != "") {
        queryString.push(`filename=${this.parameters.filename}`);
      }
      return queryString.join("&");
    },
  },
  created() {
    this.model = _.cloneDeep(this.baseModel);
    if (this.$route.query.status && this.$route.query.status !== "") {
      this.parameters.status = this.$route.query.status;
    }

    if (this.$route.query.uploaded_by && this.$route.query.uploaded_by !== "") {
      this.parameters.uploaded_by = this.$route.query.uploaded_by;
    }
 
    if (this.$route.query.video_title && this.$route.query.video_title !== "") {
      this.parameters.video_title = this.$route.query.video_title;
    }

    if (this.$route.query.filename && this.$route.query.filename !== "") {
      this.parameters.filename = this.$route.query.filename;
    }

    if (this.$route.query.order && this.$route.query.order !== "") {
      this.parameters.order = this.$route.query.order;
    }

    if (this.$route.query.dir && this.$route.query.dir !== "") {
      this.parameters.dir = this.$route.query.dir;
    }

    if (this.$route.query.page && this.$route.query.page > 0) {
      this.parameters.page = this.$route.query.page;
    }

    if (this.$route.query.page_size && this.$route.query.page_size > 0) {
      this.parameters.page_size = this.$route.query.page_size;
    }    
  },
  mounted() {
  },
  methods: { 
    async save() {
      if (!this.$refs.form.validate()) return false;
      const errors = [];
      const url = "/rest/creative_videos";
      const model = _.cloneDeep(this.model);
      if (this.action === "add") {
        this.model.created_by = this.loggedInUser.id;
        await this.postData(url, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `
                Successfully added ${model.name} (${model.email}).
                `,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (this.action === "edit") {
        this.model.updated_by = this.loggedInUser.id;
        const model = _.cloneDeep(this.model);

        this.putData(`${url}/${this.model.id}`, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `
                Successfully updated ${model.name} (${model.email}).
                `,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }

      if (errors.length > 0) {
        this.$refs.container.snackBar(
          `
                Error updating item: ${errors.join(",")}
                `,
          "red",
          2000
        );
      } else {
        this.reset();
      }
    },
    onItemView(item) {
      this.model = _.cloneDeep(item);
      this.showInformation = true;
    },
    getMetaData(inputMessage) {
      let message;
      if (inputMessage) {
        if (Object.keys(inputMessage).length > 0) {
          message = inputMessage
        } else {
          return []; 
        }
      } else return []
      
      const arr = [];      
      Object.keys(message).forEach((d) => {
        arr.push({
          label: d,
          status: message[d]["status"],
          allowed: message[d][`allowed_${d}`],
          current: message[d][`current_${d}`],
        });
      });
      return arr;
    },
    async retry() {
      const asset = this.model;
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You're about to reset transcoding for <strong>${this.model.VideoTitle} (${this.model._id})</strong>. Do you want to continue?`,
          "red"
        )
        .then(async (ans) => {
          if (ans) {
            await this.putData(`/rest/creative_videos/${this.model._id}`, {
              Status: 'Ready',
              EncodingError: '', //this.model.EncodingError,
              retry: true
            })
              .then((response) => { 
                this.$refs.container.snackBar(
                  "Successfully move to queue for transcoding.",
                  "red",
                  2000
                );
                this.showInformation = false;
              })
              .catch((err) => { 
                setTimeout(() => {
                  this.busy = false;
                }, 200);
              });
          } else {
            setTimeout(() => {
              this.showInformation = false;
            }, 200);
          }
        });
    },
    setBusy(value) {
      console.log(value)
      if (!value.busy) {
        this.parameters.page = value.paging.currentPage;
       // this.changeQueryString(this.parameters);
      }
    },
    reset(par) {
      this.action = "";
      this.model = _.cloneDeep(this.baseModel);
      if (par) {
        this.parameters = {
          status: ["Encoding Error", "Ready"],
          uploaded_by: "",
          video_title: "",
          filename: "",
          order: "CreatedDate",
          dir: "desc",
          page: 1,
          page_size: 20,
        };
      }
      //this.changeQueryString(this.parameters);
      setTimeout(async () => {
        this.$refs.table.refresh();
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped></style>
